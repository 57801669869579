@import '../abstracts/variables.scss';
@import '../abstracts/mixins.scss';
@import '../sections/base.scss';

.pb-testimonial-carousel {
    h3 {
        &.carousel-heading {
            color: $zovblue;
            margin-bottom: 0.5em;
            font-family: $font-med;
            font-size: clamp(30px,4vw,50px);
        }
    }
    h4 {
        &.carousel-title {
            font-family: $font-family;
            margin-bottom: 1.25em;
        }
    }
    .testimonial-carousel {
        .slide-item {
            .inner {
                width: 100%;
                max-width: calc(1200px + 150px);
                margin: 0 auto;
                padding: 0 75px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                .credit-row {
                    display: flex;
                    gap: 2em;
                    align-items: center;
                    justify-content: center;
                    .credit-image {
                        .inner {
                            //border: 1px solid;
                            border-radius: 50%;
                            overflow: hidden;
                            width: 150px;
                            height: 150px;
                            padding: 0;
                            img {
                                width: 100%;
                                height: auto;
                                display: block;
                            }
                        }
                    }
                    .credit-credit {
                        span {
                            font-size: 30px;
                            display: block;
                            &.name {
                                font-family: $heading;
                            }
                        }
                    }
                }
            }
        }
    }
    @include bpr-media-breakpoint(down, xxl) {
        .slick-btn {
            &.prev-btn-testimonial-carousel {
                left: 0;
            }
            &.next-btn-testimonial-carousel {
                right: 0;
            }
        }
	}
    @include bpr-media-breakpoint(down, lg) {
        .testimonial-carousel {
            .slide-item {
                .inner {
                    .credit-row {
                        .credit-image {
                            .inner {
                                width: 100px;
                                height: 100px;
                            }
                        }
                        .credit-credit {
                            span {
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
        }
	}
    @include bpr-media-breakpoint(down, md) {
        .testimonial-carousel {
            .slide-item {
                .inner {
                    padding: 0;
                }
            }
        }
        .slick-btn {
            display: none;
        }
	}
    @include bpr-media-breakpoint(down, sm) {
        .testimonial-carousel {
            .slide-item {
                .inner {
                    .credit-row {
                        gap: 1em;
                        flex-direction: column;
                    }
                }
            }
        }
	}
}